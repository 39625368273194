<template>
    <Header active_menu="5" />
    <div class="flexjc">
        <div class="content">
            <div class="imgbox relative">
                <div><img class="content_img" src="../assets/img/contentUs.png" alt="">
                    <div class="absolute us">{{ $t('联系') }}/Contact Us</div>
                </div>
                <div class="box flexJBC">
                    <div class="box_left">
                        <div class="flex">
                            <div class="name">{{ $t('咨询问题') }}</div>
                            <span class="xing">*</span>
                            <select class="select" name="" id="" style="width:180px;padding-right: 14px;">
                                <option value="关于一般服务">{{$t('关于一般服务')}}</option>
                                <option value="关于报道和新闻关系">{{$t('关于报道和新闻关系')}}</option>
                                <option value="股票/投资者关系">{{$t('股票/投资者关系')}}</option>
                                <option value="关于招聘">{{$t('关于招聘')}}</option>
                            </select>
                        </div>
                        <div class="flex">
                            <div class="name">{{ $t('案件名') }}</div>
                            <span class="xing">*</span>
                            <input class="input" type="text" :placeholder="$t('请输入案件名')">
                        </div>
                        <div class="flex">
                            <div class="name">{{ $t('姓名') }}</div>
                            <span class="xing">*</span>
                            <input class="input" type="text" :placeholder="$t('请输入姓名')">
                        </div>
                        <div class="flex">
                            <div class="name">{{ $t('联系方式') }}</div>
                            <span class="xing">*</span>
                            <input class="input" type="text" :placeholder="$t('手机/电话/邮箱')">
                        </div>
                        <div class="flex">
                            <div class="name">{{ $t('询问内容') }}</div>
                            <span class="xing">*</span>
                            <textarea class="textarea" name="" id="" cols="30" rows="10"
                                :placeholder="$t('请输入咨询内容')"></textarea>
                        </div>
                        <div class="submit cur">{{$t('提交')}}</div>
                    </div>
                    <div class="box_right">
                        <div class="com">公司</div>
                        <div class="com_en" style="margin:14px 0 28px">Business cooperation</div>
                        <div class="adress" style="margin:0  0 28px">{{$t('地址：海南省海⼝市琼⼭区海航豪庭南苑⼆区1栋4302')}}</div>
                        <div class="email">{{$t('企业邮箱')}}： info@aucfan-cn.com</div>
                    </div>

                </div>

            </div>
            <div class="COPY">COPYRIGHT(@)2022mo005_19560-{{$t('企业集团类官网')}}</div>

        </div>

    </div>


</template>   

<script>
// import { Swiper, SwiperSlide } from "swiper/vue";
// import { toRaw } from '@vue/reactivity'
import Header from '../components/Header2.vue'
import { reactive, toRefs } from "vue";


export default {
    name: 'Home',

    setup() {
        let active = reactive({

        })

        return {
            ...toRefs(active),
        };
    },
    components: {
        Header,
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  scoped>
.box_right {
    width: 550px;
    height: 608px;
    background: #1F4A92;
    padding-left: 125px;
    padding-top: 205px;
    box-sizing: border-box;
    font-size: 14px;
    color: rgba(255, 255, 255, .6);
}

.com {

    font-size: 24px;
    color: #fff !important;
}

.submit {
    width: 112px;
    height: 40px;
    background: #1F4A92;
    text-align: center;
    line-height: 40px;
    font-size: 14px;
    color: #FFFFFF;
    margin: 40px 100px;
}

.flex {
    align-items: center;

}

.name {
    width: 80px;
    text-align: right;
}

.xing {
    color: #FF3838;
    margin-right: 12px;
}

input,
select {
    width: 363px;
    /* width: 133px; */
    height: 38px;
    background: #FFFFFF;
    border: 1px solid #666666;
    margin-top: 20px;
    padding: 0 14px;
    box-sizing: border-box;
}

textarea {
    margin-top: 20px;
    width: 363px;
    padding: 14px;
    box-sizing: border-box;
}

.box {
    max-width: 75%;
    /* height: 608px; */
    background: #fff;
    margin: -100px auto 0;
    /* padding: 51px 0 51px 51px; */
    box-sizing: border-box;
    position: relative;
    z-index: 99;
}

.us {
    /* max-width: 80%; */
    margin: 0 auto;
    left: 250px;
    color: #FFFFFF;
    font-size: 24px;
    top: 260px;
}
.content_img {
    width: 1423px;
    height: 500px;
}

@media screen and (max-width:1000px) {
    .content_img {
        /* display: none; */
        width: 100vw;
        /* border: 1px solid red; */
        height: 50vw;
    }

    .box {
        padding: 0;
        margin: -40px auto;
        /* height: 600px; */
        /* border: 1px solid red; */
    }

    .name {
        width: 35px;
        font-size: 8px;
        /* line-height: 28px !important; */
    }

    .xing {
        margin-right: 2px;
    }

    .select {
        width: 100px !important;
        margin-top: 10px;
        height: 20px;
        font-size: 8px;
    }

    .input {
        font-size: 8px;
        width: 100px !important;
        margin-top: 10px;
        height: 20px;
    }

    .textarea {
        margin-top: 10px;
        margin-bottom: 10px;
        width: 100px;
        height: 80px;
        font-size: 8px;
        padding: 2px;
    }

    .submit {
        margin: 0;
        width: 50px;
        font-size: 10px;
        line-height: 25px;
        height: 25px;
        margin-left: 50px;
    }

    .box_left {
        /* border: 1px solid red; */
        /* height: 600px; */
    }

    .box_right {
        width: 120px;
        height: 300px;
        padding-left: 10px;

        padding-top: 20px;
        /* border: 1px solid red; */
    }

    .com {
        font-size: 15px;
    }

    .com_en {
        font-size: 10px;
    }
    .adress{
        font-size: 8px;
    }
    .email{
        font-size: 8px;
    }
    .COPY{
        font-size: 8px;
    }
}
</style>
